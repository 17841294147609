<template>
  <div class="min-h-screen relative">
    <UINav class="z-10" dark />
    <div class="top-0 right-0 left-0 w-screen max-h-[900px] max-w-screen-2xl 2xl:mx-auto absolute -z-10 bg-darkestGreen">
      <video autoplay muted loop class="w-full brightness-[0.60] object-cover pointer-events-none min-h-[400px]">
        <source src="https://www.propellerfestival.de/images/header_mov.mp4" type="video/mp4" />
      </video>
      <div class="wave">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
        </svg>
      </div>
    </div>
    <slot />
  </div>
  <UIFooter />
</template>

<script lang="ts" setup></script>

<style>
.wave {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 181px;
}

.wave .shape-fill {
  fill: #ffffff;
}
</style>
